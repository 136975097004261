// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html,\nbody,\n.index_app_eifea {\n    /* probably unecessary, transitional until layout is refactored */\n    width: 100%; \n    height: 100%;\n    margin: 0;\n\n    /* Setting min height/width makes the UI scroll below those sizes */\n    min-width: 1024px;\n    min-height: 640px; /* Min height to fit sprite/backdrop button */\n}\n\n/* @todo: move globally? Safe / side FX, for blocks particularly? */\n\n* { -webkit-box-sizing: border-box; box-sizing: border-box; }\n\n[theme='dark'] body {\n    background-color: var(--clipcc-ui-white);\n}\n", "",{"version":3,"sources":["webpack://./src/playground/index.css"],"names":[],"mappings":"AAAA;;;IAGI,iEAAiE;IACjE,WAAW;IACX,YAAY;IACZ,SAAS;;IAET,mEAAmE;IACnE,iBAAiB;IACjB,iBAAiB,EAAE,6CAA6C;AACpE;;AAEA,mEAAmE;;AACnE,IAAI,8BAAsB,EAAtB,sBAAsB,EAAE;;AAE5B;IACI,wCAAwC;AAC5C","sourcesContent":["html,\nbody,\n.app {\n    /* probably unecessary, transitional until layout is refactored */\n    width: 100%; \n    height: 100%;\n    margin: 0;\n\n    /* Setting min height/width makes the UI scroll below those sizes */\n    min-width: 1024px;\n    min-height: 640px; /* Min height to fit sprite/backdrop button */\n}\n\n/* @todo: move globally? Safe / side FX, for blocks particularly? */\n* { box-sizing: border-box; }\n\n[theme='dark'] body {\n    background-color: var(--clipcc-ui-white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": "index_app_eifea"
};
export default ___CSS_LOADER_EXPORT___;
